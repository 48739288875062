// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@material/density' as mdc-density;
@use '@material/textfield' as mdc-textfield;
@use '@material/button' as mdc-button;
// Plus imports for other components in your app.

// AG-Grid SASS APIs
// @use 'node_modules/ag-grid-community/styles' as ag;
// @include ag.grid-styles(
//   (
//     theme: alpine,
//   )
// );
// Importing ag-grid related stylesheets
@import 'ag-grid-community/styles/ag-grid.css';
// @import 'ag-grid-community/styles/ag-theme-material.min.css';
@import 'ag-grid-community/styles/ag-theme-alpine.min.css';
// @import 'ag-grid-community/styles/ag-theme-balham.min.css';
// Custom AG-Grid styles
@import 'assets/styles/ag-grid.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary-palette: (
  50: #e5f2fc,
  100: #c0def9,
  200: #99caf5,
  300: #75b6f0,
  400: #5da6ed,
  500: #4c97e9,
  600: #4789db,
  700: #3f77c8,
  800: #3966b6,
  900: #254d93,
  contrast: (
    50: #000000de,
    100: #000000de,
    200: #000000de,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$secondary-palette: (
  50: #e6f6e7,
  100: #c4e8c5,
  200: #9dd9a0,
  300: #72c884,
  400: #51c05a,
  500: #28b43b,
  600: #1da532,
  700: #079326,
  800: #00821a,
  900: #006304,
  contrast: (
    50: #000000de,
    100: #000000de,
    200: #000000de,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$main-typography: mat.define-typography-config(
  $font-family: 'Poppins',
  $body-1:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem,
    ),
  $body-2:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem,
    ),
  $caption:
    mat.define-typography-level(
      $font-weight: 400,
      $font-size: 0.8rem,
    ),
  $button:
    mat.define-typography-level(
      $font-weight: 500,
      $font-size: 1rem,
    ),
);

$material-default-primary: mat.define-palette($primary-palette, 800);
$material-default-accent: mat.define-palette($secondary-palette, 400, 200, 600);

// The warn palette is optional (defaults to red).
$material-default-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$material-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $material-default-primary,
      accent: $material-default-accent,
      warn: $material-default-warn,
    ),
    density: 0,
    typography: $main-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($material-default-theme);

.density_-1 {
  @include mat.autocomplete-density(-1);
  @include mat.badge-density(-1);
  @include mat.bottom-sheet-density(-1);
  @include mat.button-density(-1);
  @include mat.button-toggle-density(-1);
  @include mat.card-density(-1);
  @include mat.checkbox-density(-1);
  @include mat.chips-density(-1);
  @include mat.core-density(-1);
  @include mat.datepicker-density(-1);
  @include mat.dialog-density(-1);
  @include mat.divider-density(-1);
  @include mat.expansion-density(-1);
  @include mat.fab-density(-1);
  @include mat.form-field-density(-1);
  @include mat.grid-list-density(-1);
  @include mat.icon-button-density(-1);
  @include mat.icon-density(-1);
  @include mat.input-density(-1);
  @include mat.list-density(-1);
  @include mat.menu-density(-1);
  @include mat.optgroup-density(-1);
  @include mat.option-density(-1);
  @include mat.paginator-density(-1);
  @include mat.progress-bar-density(-1);
  @include mat.progress-spinner-density(-1);
  @include mat.radio-density(-1);
  @include mat.select-density(-1);
  @include mat.sidenav-density(-1);
  @include mat.slide-toggle-density(-1);
  @include mat.slider-density(-1);
  @include mat.snack-bar-density(-1);
  @include mat.sort-density(-1);
  @include mat.stepper-density(-1);
  @include mat.table-density(-1);
  @include mat.tabs-density(-1);
  @include mat.toolbar-density(-1);
  @include mat.tooltip-density(-1);
  @include mat.tree-density(-1);
}

html,
body {
  height: 100%;
  font-size: var(--contentfont);
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f2f3f4;
  color: var(--textgrey) !important;
  scroll-behavior: smooth;
  @include mat.form-field-density(-5);
  @include mat.optgroup-density(-4);
  @include mat.option-density(-4);
  @include mat.select-density(-4);
  @include mat.button-toggle-density(-3);
}

html {
  --mat-standard-button-toggle-selected-state-text-color: #131c2b;
  --mat-standard-button-toggle-selected-state-background-color: #dae2f9;
}

small {
  color: unset !important; // Overriding the Bootstrap default color
}

/*
* The below 4 class styles are a workaround to support contrast colors for buttons.
* This is an unresolved bug in Angular Material 15. We can remove the below classes once the issue is resolved.
* https://github.com/angular/components/issues/26056
*/
.mat-mdc-unelevated-button.mat-primary {
  // --mdc-filled-button-label-text-color: var(--material-default-primary-contrast-500);
  --mdc-filled-button-label-text-color: #fff;
  --mdc-outlined-button-container-height: 50px;
}

.mat-mdc-unelevated-button.mat-accent {
  // --mdc-filled-button-label-text-color: var(--material-default-accent-contrast-500);
  --mdc-filled-button-label-text-color: #fff;
  --mdc-outlined-button-container-height: 50px;
}

.mat-mdc-raised-button.mat-primary {
  // --mdc-protected-button-label-text-color: var(--material-default-primary-contrast-500);
  --mdc-protected-button-label-text-color: #fff;
  --mdc-outlined-button-container-height: 50px;
}

.mat-mdc-raised-button.mat-accent {
  // --mdc-protected-button-label-text-color: var(--material-default-accent-contrast-500);
  --mdc-protected-button-label-text-color: #fff;
  --mdc-outlined-button-container-height: 50px;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff !important;
}

.mat-mdc-list-option .mdc-list-item__start,
.mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start,
.mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

// .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
//   --mat-mdc-form-field-label-offset-x: unset !important;
// }

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 8px !important;
}

.mat-mdc-option {
  min-height: 40px !important;
}

// .mat-mdc-button.mat-mdc-button-base,
// .mat-mdc-raised-button.mat-mdc-button-base,
// .mat-mdc-unelevated-button.mat-mdc-button-base,
// .mat-mdc-outlined-button.mat-mdc-button-base {
//   height: 34px;
// }

.mat-mdc-menu-item {
  min-height: 38px !important;
}

.mat-mdc-paginator-container {
  min-height: unset !important;
}

.mat-mdc-form-field-hint {
  color: #666;
}

/*
 *
 * Angular Custom Theming ends here
 *
 */

:root {
  //color
  // --themeblue: #254d93 !important; original color
  --themeblue: #3966b6 !important;
  --themegreen: #72c884 !important;
  --themelightblue: #4b85ea !important;

  --text-dark: #333 !important;
  --textgrey: #202124 !important;
  // --textgrey: #606060 !important;
  --textlightgrey: #464646 !important;

  --blockgrey: #a3a3a3 !important;
  --blockmedgrey: #f0f0f0 !important;
  --blocklightgrey: #fafafa !important;

  --ipstts: #ffb545 !important;
  --completedstts: #6dafed !important;
  --approvedstts: #64c479 !important;
  --updatestts: #fb6666 !important;

  --strokeline: #dcdcdc !important;

  --danger: #dc3545 !important;
  --warn: #eb9a44 !important;

  // --tablehead:#556080 !important;
  --tablehead: #3a5584 !important;
  --tablehightlight: #edf1ff !important;

  //fontsize
  --contentfont: 13.12px !important;
  // --contentfont:.75rem !important;
  --labelfont: 0.75rem !important;
  --form1004font: 13.5px !important;

  //fontweight
  --reg: 400 !important;
  --med: 500 !important;
  --semiB: 600 !important;
  --bold: 700 !important;
}

// scroll bar styling
* {
  scrollbar-width: thin;
  scrollbar-color: #a9a9a9 #dcdcdc;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #dcdcdc;
}

*::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 20px;
  border: 3px solid #dcdcdc;
}

// scroll bar styling ends

/* Progress Spinner Styles */
.progress-overlay {
  background-color: #254d9338;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 50000;
  top: 0;
  left: 0px;

  .progress-container {
    background-color: #fff;
    padding: 5px 35px;
    border-radius: 5px;

    .ellipse {
      display: inline-block;
      min-width: 15px;
      text-align: start;
    }
  }
}

/* Progress Spinner Styles End */

/**
  * Google Auto Complete Customization
  */

.pac-container.pac-logo {
  .pac-item {
    font-size: 13px;
    padding: 4px 10px;

    .pac-icon.pac-icon-marker {
      display: none;
    }

    .pac-item-query {
      font-size: 14px;

      .pac-matched {
        font-weight: 500;
      }
    }
  }
}

/**
  * Google Auto Complete Customization Ends Here
  */

/**
  * Common Sliding Sheet Customization
  */

.sliding-sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;

  h3 {
    margin-left: 2rem;
    margin-bottom: 0;
  }

  button.sliding-sheet-close {
    padding: 10px 10px 5px;
    color: #fff;
    background-color: var(--themeblue);
    border: none;
  }
}

.sliding-sheet-body {
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
}

.sliding-sheet-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 10px 30px;

  &.shadow {
    box-shadow:
      rgba(0, 0, 0, 0.1) 0px -4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px -2px 4px -1px !important;
  }
}

/**
  * Common Sliding Sheet Customization Ends Here
  */

#parent svg * {
  font-size: 10px !important;
  /*added to fix text break in neighbrhood trends graph*/
}

.app-sidenav-content {
  display: flex !important;
  flex-direction: column;
  overflow-y: hidden !important;
}

// body wrap, NOTE: all contents come inside this div
.body_wrap {
  // flex: 1;
  width: 100%;
  // overflow-y: auto;
  height: calc(100vh - 56px); // Minus the header height
  margin-top: 56px;
}

// fix for property details url scroll
.body_wrap:has(> app-property-page) {
  overflow-y: auto;
}

// // mat-icon color start=====================================
// mat-icon[svgIcon] svg {
//   fill: var(--textlightgrey);
// }
// mat-icon[svgIcon][color='white'] svg {
//   fill: #fff;
// }
// mat-icon[svgIcon][color='primary'] svg {
//   fill: var(--themeblue);
// }
// mat-icon[svgIcon][color='secondary'] svg {
//   fill: var(--themegreen);
// }
// mat-icon[svgIcon][color='danger'] svg {
//   fill: var(--updatestts);
// }
// mat-icon[svgIcon][color='accent'] svg {
//   fill: var(--themelightblue);
// }
// // mat-icon color end=====================================

.text_themeBlue {
  color: var(--themeblue);
}

p {
  font-size: var(--contentfont) !important;
}

label {
  // font-size: var(--labelfont);
  color: var(--textlightgrey);
  font-weight: var(--reg);
}

textarea {
  font-size: var(--contentfont) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(196, 201, 210) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(196, 201, 210) !important;
}

// drawer backdrop
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #49507580 !important;
}

// Mat Form Field Styles
mat-form-field {
  &.mat-form-field-sm {
    .mat-mdc-form-field-infix {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      min-height: unset !important;
    }
  }
}

// heading styles
h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px !important;
}

// BUTTONS start======================================================

.mdc-button {
  // font-size: 0.9rem !important;
  // letter-spacing: 0.2px;
}

.mat-mdc-unelevated-button:not(:disabled)[color='danger'] {
  background-color: var(--updatestts) !important;
  color: #fff;
}

.mat-mdc-unelevated-button:not(:disabled)[color='warn'] {
  background-color: var(--warn) !important;
}

.mat-mdc-mini-fab[color='primary'] {
  background-color: var(--themeblue) !important;
  color: #fff !important;
}

.mat-mdc-mini-fab[color='accent'] {
  background-color: var(--themegreen);
  color: #fff !important;
}

.mat-mdc-unelevated-button:not(:disabled)[color='accent'] {
  background-color: var(--themegreen) !important;
  color: #fff;
}

// strokedbutton---------------------------------
.mat-mdc-outlined-button:not(:disabled) {
  color: var(--textlightgrey) !important;
}

.mat-mdc-outlined-button:not(:disabled)[color='primary'] {
  border-color: var(--themeblue) !important;
  color: var(--themeblue) !important;
}

.mat-mdc-outlined-button:not(:disabled)[color='accent'] {
  border-color: var(--themegreen) !important;
  color: var(--themegreen) !important;
}

.mat-mdc-outlined-button:not(:disabled)[color='danger'] {
  border-color: var(--updatestts) !important;
  color: var(--updatestts) !important;
}

// textbutton--------------------------------------
.mat-mdc-button:not(:disabled)[color='danger'] {
  color: var(--updatestts) !important;
}

// // buttontoggle start ------------------------------
// .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
//   img {
//     filter: brightness(0);
//   }
// }
// .mat-button-toggle-checked {
//   background-color: var(--themeblue) !important;
//   color: #fff !important;
//   img {
//     filter: brightness(100) !important;
//   }
// }

// popupclosebutton---------------------------------
button.mdc-icon-button.mat-mdc-icon-button.popclose {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  // background-color: #eaeaea;
  padding: 0;

  img {
    width: 11px !important;
    height: auto !important;
    margin-top: -4px !important;
  }
}

// micro-fab button NOTE:should have mat-button attribute--------------
.micro_fab {
  width: 30px !important;
  height: 30px !important;
  min-width: unset !important;
  border-radius: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;

  svg {
    width: 12px !important;
    filter: brightness(100);
  }

  &[color='primary'] {
    background-color: var(--themeblue);
  }

  // &[color='secondary'] {
  //   background-color: var(--themegreen);
  // }
  &[color='danger'] {
    background-color: var(--updatestts);
  }
}

// small button NOTE:apply class small_btn to make the normal button small, should have mat-button attribute-------
.small_btn {
  padding: 0 1.2em;
  height: 30px !important;
}

.btn-show-info {
  position: absolute;
  height: 20px;
  width: 20px;
  font-size: 20px;
  cursor: pointer;
}

// BUTTONS end======================================================

/**
  * SnackBar Styles
  */

.mdc-snackbar__label {
  padding: 0 !important;
}

.mdc-snackbar__surface {
  padding: 0 !important;
}

// .mat-mdc-snack-bar-container .mdc-snackbar__surface {
//   // border-radius: unset !important;
// }

.snackbar {
  --mdc-snackbar-container-color: #ffffff;
  // --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #444444;
  margin-top: 3rem !important;
}

.snackbar-error {
  --mdc-snackbar-container-color: #ffffff;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #b43939;
}

.snackbar-warning {
  --mdc-snackbar-container-color: #ffffff;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #b07829;
}

.snackbar-info {
  --mdc-snackbar-container-color: #ffffff;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #02589f;
}

.snackbar-success {
  --mdc-snackbar-container-color: #ffffff;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #3d914d;
}

/**
  * SnackBar Styles End
  */

// selectcheck
// .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
// .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
// .mat-mdc-list-option
//   .mdc-checkbox
//   .mdc-checkbox__native-control[data-indeterminate='true']:enabled
//   ~ .mdc-checkbox__background {
//   // background-color: var(--themeblue) !important;
//   // border-color: var(--themeblue) !important;
// }

// active menu item highlighting
.menu_active {
  background-color: var(--themelightblue) !important;

  * {
    color: #fff !important;
  }
}

// toggleswitch
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: var(--themeblue) !important;
  opacity: 0.7;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background-color: var(--themeblue) !important;
}

// text editor
app-text-editor {
  display: block;
  padding: 10px;
  border: 1px solid var(--blockgrey);
  border-radius: 5px;
}

// form-fields start=========================================================

// datepicker------------------------------
mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  width: 38px;
  height: 38px;
  padding: 7px;
}

// form-fields end===========================================================

// Mat Table Common Styles
.mat-mdc-cell,
.mat-mdc-header-cell {
  white-space: nowrap;
  font-size: 12px;
}

.status_base {
  font-size: 0.75rem;
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 0px !important;
  padding: 1px 10px;
  background-color: var(--ipstts);
  color: #fff;
  text-transform: capitalize;
}

// property status and property type
.propstatus_base,
.proptype_pill {
  font-size: 11px !important;
  padding: 1.8px 10px;
  display: inline-block;
  border-radius: 5px;
  margin-bottom: 0px !important;
  background-color: #7996ca;
  color: #fff;

  &.active {
    background-color: #71b37f;
    color: #fff !important;
  }

  &.sold {
    background-color: var(--updatestts); //don't change
  }

  &.pending {
    background-color: #b09f45;
  }

  &.foreclosure {
    background-color: #ab326a;
  }

  &.expired {
    background-color: #b04545;
  }

  &.cancelled {
    background-color: #919191;
  }

  &.delisted {
    background-color: #919191a2;
  }

  &:empty {
    display: none;
  }
}

// Non angular general table
.gen_table {
  white-space: nowrap;
  font-size: var(--contentfont);
  height: 1px;
  border-collapse: separate;
  border-spacing: 0px;

  .mat-sort-header-arrow {
    color: #fff;
  }

  .mat-mdc-header-row {
    // height: unset !important;
    min-height: 37px;
  }

  .mat-mdc-row {
    height: unset !important;
    min-height: 37px;
  }

  thead {
    background-color: var(--tablehead) !important;
    color: #fff;

    th {
      font-weight: var(--reg);
      background-color: var(--tablehead);
      color: #fff !important;
      position: sticky;
      top: 0;
      border: 1px solid #ddd;
    }
  }

  tbody {
    color: var(--textgrey);
    font-size: var(--contentfont);

    tr {
      vertical-align: middle;

      td {
        border: 1px solid #ddd;
        line-height: 1.2;
      }
    }
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    font-size: var(--contentfont);
    width: max-content;
  }
}

// calender component-----------------
.mat-calendar-body-selected {
  background-color: var(--themeblue) !important;
}

// search filter style
.searchfilter {
  .mat-mdc-form-field-subscript-wrapper {
    // display: none;
  }
}

//mat-card padding
.mat-mdc-dialog-surface {
  padding: 1rem;
}

.info-window-container {
  display: inline-block;

  .info-window-header {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .info-window-header .index {
    min-width: 16px;
    min-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 5px;
    background-color: #e7e7e7;
    border: 1px solid #c5c5c5;
    align-self: flex-start;
    line-height: 1;
    margin-right: 1rem;
    color: #666;
  }

  .info-window-header .address h3 {
    margin: 0;
    font-size: 1.1rem;
  }

  .info-window-header .address span {
    font-size: 0.8rem;
  }

  .info-window-header .close {
    margin-left: 1rem;
    padding: 0.7rem;
    align-self: center;
    cursor: pointer;
    border-radius: 50%;
  }

  .info-window-header .close:hover {
    background-color: #ddd;
  }

  .info-window-body {
    display: flex;
  }

  .info-window-property-image {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    font-size: 0.8rem;
    align-items: center;
    border-radius: 5px;
    width: 180px;
    justify-content: flex-end;

    img {
      width: 180px;
      height: 120px;
      object-fit: cover;
    }

    .info-window-property-image-footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 4px;

      .info-window-property-status {
        color: #fff;
        background-color: #38af38;
        font-size: 0.7rem;
        font-weight: 500;
        padding: 1px 5px;
        border-radius: 5px;
        border: 1px solid #fff;
        text-transform: uppercase;
        line-height: 2;
      }

      .info-window-property-distance {
        // position: absolute;
        // bottom: 0;
        // right: 0;
        background-color: #ffffb0;
        padding: 3px 5px;
      }
    }
  }

  .info-window-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  // .info-window-btn {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   cursor: pointer;
  //   // border: 1px solid rgb(202, 202, 202);
  //   // background-color: #e5e9ff;
  //   border: none;
  //   background-color: transparent;
  //   font-weight: 500;
  //   border-radius: 4px;
  //   color: #5867dd;
  //   padding: 6px 23px;
  //   font-size: 0.9rem;
  //   &:hover {
  //     background-color: var(--blockmedgrey);
  //   }
  //   &:focus-visible {
  //     border: none;
  //     outline: none;
  //   }
  // }
  // .info-window-btn span.material-symbols-outlined {
  //   font-size: 1.2rem;
  //   padding-bottom: 0px;
  //   margin-right: 4px;
  // }
  // .info-window-btn.info-window-btn-select {
  //   background-color: #5867dd;
  //   color: #fff;
  //   &:hover {
  //     background-color: #4d5cca;
  //   }
  // }
  // .info-window-btn.info-window-btn-deselect {
  //   background-color: #d45f65;
  //   color: #fff;
  //   &:hover {
  //     background-color: #be5258;
  //   }
  // }
  // .info-window-btn.info-window-btn-select img {
  //   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(116deg) brightness(107%) contrast(102%);
  // }
  // .info-window-btn.info-window-btn-deselect img {
  //   filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(116deg) brightness(107%) contrast(102%);
  // }
  // .info-window-btn.info-window-btn-detail img {
  //   filter: invert(42%) sepia(72%) saturate(573%) hue-rotate(196deg) brightness(87%) contrast(99%);
  // }
}

.gm-style .gm-style-iw-c {
  max-width: unset !important;
  max-height: unset !important;
  padding: 0 !important;
}

.gm-style-iw-chr {
  > div {
    display: none;
  }

  button {
    position: absolute !important;
  }
}

.gm-style-iw-d {
  overflow: unset !important;
  padding: 15px !important;

  .info-window-property-status.active {
    color: #fff !important;
  }

  .property-info-row {
    margin-bottom: 0.5rem;
  }
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
  width: unset !important;
  height: unset !important;

  span {
    width: 18px !important;
    height: 18px !important;
  }
}

// Property Details section-----------------------------------------------

.pprt_dtls {
  .prop_brief {
    position: sticky;
    top: 0px;
    background-color: var(--blocklightgrey);
    z-index: 2;

    .property-address {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1em;
      margin-bottom: 1em;

      .prop_name {
        display: flex;
        flex-direction: column;
        width: fit-content;

        h3 {
          font-weight: var(--semiB);
          margin-bottom: 0;
          font-size: 18px;
        }

        p {
          font-size: 14px !important;
          font-weight: var(--reg);
          float: left;
          margin-bottom: 0;
        }
      }
    }

    .prop_aments {
      div {
        display: flex;
        align-items: center;

        img {
          opacity: 0.5;
        }

        span {
          font-size: var(--contentfont);
        }
      }
    }

    .prop_price {
      h2 {
        font-weight: var(--semiB);
        display: inline;
      }

      div:nth-child(2) {
        h2 {
          color: var(--updatestts);
        }

        p {
          font-size: var(--contentfont);
          margin-top: -7px;
        }
      }
    }
  }

  .propdtls_body {
    height: calc(100vh - 13em);
    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-x: clip;

    .sub_hero {
      height: 300px;
      // overflow: hidden;
      background-color: var(--blocklightgrey);
    }
  }
}

.property-primary-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.utility_menu {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--blockmedgrey);
}

.data_source {
  height: 16px;
  // font-size: 11px;
  font-size: 0px;
  color: #fff;
  width: 16px;
  padding: 0 5px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  text-transform: capitalize;

  &::first-letter {
    font-size: 11px;
  }

  &:empty {
    display: none !important;
    height: 0px;
  }

  &.listing {
    background-color: #589ec9;
  }

  &.inspection {
    background-color: #f47676;
  }

  &.pubrec {
    background-color: #98be5c;
  }

  &.user {
    background-color: #ff9c3f;
  }
}

.review_key_dtls {
  border: 1px solid var(--strokeline);
  padding: 0.5em;
  margin-bottom: 0;
  // display: flex;
  font-size: var(--contentfont);
  align-items: center;
  min-height: 34px;
  height: 100%;
  position: relative;

  label {
    color: var(--textlightgrey);
    flex-basis: 50%;
    text-transform: uppercase;
    display: block !important;
  }

  p {
    margin: 0;
    flex-basis: 50%;
    font-weight: var(--med);
    color: var(--textgrey);
  }
}

.expanded_tablerow {
  background-color: var(--blocklightgrey);
  box-shadow: 0px 2px 5px inset #48484821;
}

// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-Exclusive for Profet valution starts from here=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

//Form side error Hint start---------------------------
// mat-form-field {
//   .error_hint {
//     width: 0;
//     height: 0;
//     border-bottom: 8px solid red;
//     border-bottom-color: red;
//     border-right: 8px solid transparent;
//     position: absolute;
//     left: -15px;
//     bottom: 1px;
//   }
// }
// *:has(> .error_hint) {
//   position: relative;
//   min-height: 15px;
// }
// .error_hint {
//   width: 0;
//   height: 0;
//   border-bottom: 8px solid red;
//   border-bottom-color: red;
//   border-right: 8px solid transparent;
//   position: absolute;
//   left: -1px;
//   bottom: 1px;
// }

mat-radio-group.error_hint {
  position: relative;
}

mat-form-field.error_hint {
  position: relative;

  &:after {
    left: 0;
    bottom: 18px;
  }
}

// mat-button-toggle-group.error_hint.bg_alrt,
// mat-button-toggle-group.error_hint.bg_warning,
// mat-button-toggle-group.error_hint.bg_error {
//   mat-button-toggle {
//     background-color: unset;
//   }
// }

.error_hint:after {
  width: 0;
  height: 0;
  border-bottom: 8px solid red;
  border-bottom-color: red;
  border-right: 8px solid transparent;
  position: absolute;
  left: -1px;
  bottom: 0px;
  content: '';
}

profet-input.error_hint {
  position: relative;

  &:after {
    left: 0;
    bottom: 18px;
  }
}

profet-input:is(.error_hint.bg_error, .error_hint.bg_warning, .error_hint.bg_alrt) {
  background-color: unset !important;
}

profet-input.error_hint.bg_error mat-form-field {
  background-color: unset !important;

  > *:first-child {
    background-color: rgba(255, 77, 77, 0.1) !important;
  }
}

mat-form-field.error_hint.bg_error {
  background-color: unset !important;

  > *:first-child {
    background-color: rgba(255, 77, 77, 0.1) !important;
  }
}

.error_hint.warning:after {
  border-bottom-color: rgb(255, 147, 22);
}

.error_hint.alrt:after {
  border-bottom-color: rgb(235, 106, 34);
}

.error_hint.bg_error {
  background-color: rgba(255, 77, 77, 0.1) !important;
}

.error_hint.bg_warning {
  background-color: rgba(255, 147, 22, 0.1) !important;
}

.error_hint.bg_alrt {
  background-color: rgba(235, 106, 34, 0.1) !important;
}

//Form side error Hint end---------------------------

.asterisk {
  color: #f00;
  font-size: 20px;
  line-height: 0;
  margin-top: 3px;
  margin-left: 6px;
}

.mat-expansion-panel-spacing {
  margin: 0px !important;
  background-color: #fafafc;
  border-radius: 0px !important;
}

// dialog scroll removal: for removing H-scroll in dialog box
.mat-mdc-dialog-surface {
  overflow: hidden !important;
}

// Basic Colors

.color-success {
  color: #55a155;
}

.color-info {
  color: #278ac4;
}

.color-warning {
  color: #c9a44c;
}

.color-danger {
  color: #da6161;
}

// Basic Colors End

mat-sidenav-container,
mat-sidenav-content,
main,
mat-sidenav {
  background-color: transparent;
  color: var(--textgrey);
  // overflow-x: hidden;
  // overflow-y: hidden !important;
}

mat-sidenav-container {
  position: fixed;
  // height: 100%;
  // min-height: 100%;
  // width: 100%;
  // min-width: 100%;
  // background-color: #f5f6fb !important;
}

.select_sub_table .table-responsive {
  max-height: calc(100vh - 28em) !important;
}

#disclosure {
  h5 {
    font-weight: 500 !important;

    strong {
      font-weight: 500 !important;
      font-size: 14px;
    }
  }
}

//creates horizontal scroll when validation slider opens
// .container-fluid.px-3 {
//   > .row:first-child {
//     width: calc(100vw - 5em);
//   }
// }
//------------------------------------

// Google Map Property Marker
.property-marker {
  position: absolute !important;
  min-height: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600 !important;
  cursor: pointer;
  background-color: #3153af;
  text-align: center;
  padding: 1px 3px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  aspect-ratio: 1/1;

  &:hover,
  &.property-marker-highlight {
    z-index: 8 !important;
    box-shadow: 0px 5px 3px 0px #0000004d;
    transform: scale(1.2);
  }
}

.property-marker-selected {
  z-index: 7 !important;
  background-color: #fff !important;
  color: #444;
  padding: 5px;
}

.marker-type-subject {
  background-color: #9c27b0;
  z-index: 6;

  &.property-marker-selected {
    border: 6px solid #9c27b0;
  }
}

.marker-type-foreclosure {
  background-color: #ab326a;
  z-index: 5;

  &.property-marker-selected {
    border: 6px solid #ab326a;
  }
}

.marker-type-active {
  background-color: #71b37f;
  z-index: 4;

  &.property-marker-selected {
    border: 6px solid #71b37f;
    // border: 6px solid #60b464;
  }
}

.marker-type-pending {
  background-color: var(--warn);
  z-index: 3;

  &.property-marker-selected {
    border: 6px solid var(--warn);
  }
}

.marker-type-sold {
  background-color: var(--updatestts);
  z-index: 2;

  &.property-marker-selected {
    border: 6px solid var(--updatestts);
  }
}

.marker-type-disabled {
  background-color: #919191;
  z-index: 1;

  &.property-marker-selected {
    border: 6px solid #919191;
  }
}

// Google Map Info Window

.address-link {
  text-decoration: unset;
  cursor: pointer;

  // color: #3966b6;
  &:hover {
    color: #3966b6;
    text-decoration: underline;
  }
}

//disabled field background-color
.mat-form-field-disabled .mdc-notched-outline div {
  background-color: rgba(206, 206, 206, 0.18);
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: rgb(33, 33, 33) !important;
}

.mat-form-field-disabled mat-label {
  color: RGBA(0, 0, 0, 0.6);
}

//disabled field background-color end

.order-sub-page-wrapper:has(#additionaldoc) {
  display: flex;
  flex-direction: column;
}

// Media Query starts --- Don't write style codes beyond this point

@media (max-width: 575.98px) {
  .pprt_dtls {
    .prop_brief {
      .prop_price {
        #soldprice {
          label {
            font-size: 13px;
          }
        }
      }
    }
  }
}

// Custom AG-Grid Style overrides

.ag-theme-material,
.ag-theme-alpine,
.ag-theme-balham {
  --ag-cell-horizontal-border: solid 1px #ccc;
  --ag-cell-vertical-border: solid 1px #ccc;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #ccc;
  --ag-range-selection-border-color: #0084f0;

  .ag-pinned-left-header,
  .ag-pinned-left-cols-container {
    box-shadow: 0px -1px 4px #000;
    z-index: 1;
  }

  .ag-header-row-column-group,
  .ag-center-cols-container {
    border-right: 2px solid #888;
  }

  .ag-row-hover {
    background-color: #e9f5ff !important;
    &::before {
      background-color: unset !important;
    }
    .property-label-cell:not(.property-section-header-first-cell) {
      background-color: #deeaf4;
    }
    .subject-cell:not(.property-section-header-cell) {
      background-color: #e9f5ff;
    }
    .property-readonly-cell {
      background-color: #d8d8d8 !important;
    }
  }

  // --ag-borders-input: solid 2px;
  .ag-cell-focus:focus-within {
    border-width: 1.5px !important;
    border-right-width: 2px !important;
  }
  .ag-cell-inline-editing {
    border-color: var(--ag-range-selection-border-color) !important;
    border-radius: unset !important;
    height: auto;
    box-shadow:
      0 3px 6px rgba(0, 0, 0, 0.16),
      0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .header-group {
    font-weight: initial;
    padding: unset;
    border-left: 1px solid #bbb;
    &.property-header-group {
      border: 0;
      border-left: 2px solid #888;
    }
    &.comparable-header-group {
      cursor: move;
    }
  }

  .property-label-cell {
    font-weight: 400;
    background-color: #eee;
    // border: 0.5px solid #ccc;
    .content {
      text-align: left;
    }
    &.ag-cell-focus:focus-within {
      border-width: 0.5px !important;
    }
  }

  .property-editor-cell {
    padding-left: 0;
    padding-right: 0;
    .content {
      // text-align: right;
    }
  }

  .property-readonly-cell {
    background-color: #ddd !important;
  }

  .unit-adjustment-cell {
    // Unit Adjustment Column Styles
  }

  .subject-cell {
    background-color: #f4fbf8;
    border: 0;
    border-right: 2px solid #888;
  }

  .comparable-cell {
    border-top: unset;
    border-bottom: unset;
    border-left: 2px solid #888;
    border-right: unset;
  }

  .adjustment-cell {
    text-align: right;
    border: 0;
    border-right: unset;
    border-left: 1px solid #ccc;
    input {
      text-align: right;
    }
  }

  .property-section-header-first-cell {
    font-weight: 600;
    background-color: #ddd;
  }

  .property-section-header-cell {
    font-size: 11px;
    font-weight: 600;
    background-color: #ddd;
    color: #666;
    text-align: center;
    &.ag-cell-focus:focus-within {
      border-width: 0.5px !important;
    }
  }

  .property-fixed-row-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cell-text-right-align {
    text-align: right;
  }
}

/**
  * Overlay Styles
  */

.adjustment-editor-overlay-panel {
  display: flex;
  // flex-direction: column;
  // min-width: 100%;
  max-width: unset;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  margin: 2px 0;
}

/**
    * Overlay Styles End
    */

.grid-renderer-cell {
  display: flex;
  height: 28px; // Change this according to [rowHeight]="30" in value-adjustment.component.html
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 10px;

  .content {
    flex: 1;
    // text-align: right;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .copy-btn {
    cursor: pointer;
    padding: 5px 0 5px 5px;
    border-radius: 5px;
    display: inline-flex !important;
    width: min-content !important;
    height: min-content !important;
    line-height: 1 !important;
    &:hover {
      // background-color: #bbb;
      font-weight: 600;
    }
  }
}

.copy-confirmation-overlay {
  display: flex;
  background-color: #fff;
  padding: 0.2rem 0.7rem;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.ag-custom-cell-editor-options-list {
  padding: 0 !important;
  .mat-mdc-option {
    min-height: 30px !important;
    padding-left: 5px;
    padding-right: 10px;
    .mdc-list-item__primary-text {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// Common styles for grid editor cell
.grid-editor-cell {
  display: grid;
  min-height: 30px;
  width: 100%;
  grid-template-columns: auto 1fr;
  align-items: center;
  border: 0 solid transparent;
  padding: 0 5px;
  background-color: #fff;
  outline: 2px solid #0084f0;

  app-data-source-badge {
    margin-right: 6px;
  }

  .input-affix {
    width: min-content;
    color: #666;
  }

  input {
    font-size: 1rem;
    width: 100%;
    border: unset;
    background-color: unset;
    text-overflow: ellipsis;
    grid-area: 1 / 2;
    &:focus-visible {
      outline: none;
    }
  }

  .copy-btn {
    cursor: pointer;
    padding: 6px 9px;
    border-radius: 50%;
    &:hover {
      background-color: #bbb;
    }
  }

  &::after,
  input::after {
    content: attr(data-value) '  ';
    visibility: hidden;
    white-space: pre-wrap;
    grid-area: 1 / 2;
  }

  &.readonly {
    background-color: #ddd !important;
  }
}

// Common styles for grid editor dropdown
.grid-editor-dropdown {
  display: flex;

  &:focus-visible,
  :focus-visible {
    outline: none;
  }

  .dropdown-content {
    padding: 10px;

    .autocomplete-item {
      display: flex;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      &:hover {
        background-color: #0000000a;
      }
      &.active-item {
        background-color: #a4d7ff !important;
      }
      &.disabled {
        color: #888;
        cursor: not-allowed;
      }

      app-data-source-badge {
        margin-right: 6px;
      }

      .pseudo-checkbox {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: #fff;
        border-radius: 4px;
        font-weight: 700;
        border: 1px solid #444;
        border-radius: 3px;
        background-color: #fff;
        margin-right: 5px;
        &.checked {
          border-width: 0;
          background-color: #0075ff;
        }
      }

      .pseudo-radio {
        width: 14px;
        height: 14px;
        font-size: 16px;
        border-radius: 50%;
        border: 2px solid #fff;
        outline: 1px solid #444;
        background-color: #fff;
        margin-right: 5px;
        &.checked {
          background-color: #0075ff;
          outline: 1px solid #0075ff;
        }
      }
    }

    .selection-counter {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      // padding-right: 1em;
      font-style: italic;
      color: #666;
      font-size: 0.9em;
      margin-top: 10px;
      &.highlight {
        color: #b86a10;
        font-weight: 600;
      }
    }
  }
}
